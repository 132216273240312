.your-resumes-container{
    background-color: var(--primary-diluated-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.your-resumes-resume-container{
    width:60%;
}

.your-resumes-message{
    font-weight: 800;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px){
    .your-resumes-resume-container{
        width: 100%;
    }
}


  
#my-custom-button:hover {
    background: var(--primary-color-dark); /* Darker background on hover */
    color: #fff;
}
  

#my-custom-button:hover {
    background: var(--primary-color-dark); /* Darker background on hover */
    color: #fff;
}



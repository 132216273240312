
.support-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100vw;
    background-color: var(--primary-diluated-color);
}

.support-header{
    margin-top:60px;
}

.support-form{
    width:600px;
}
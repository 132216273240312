.upload-container{
    background-color: var(--primary-diluated-color);
    height: 100vh;
    overflow-y: scroll;
}

.upload-container-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding:12px 16px;
    background-color: var(--primary-diluated-color);
}

#upload-container-login-btn{
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right:40px;

    &:hover{
        background-color: var(--primary-color-dark);
    }
}

.upload-container-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    margin: 10px 20px;
    margin-top: 20px;
    padding:40px;
    border-bottom:2px solid var(--primary-color);
}

.upload-container-resume{
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.upload-container-resume-dropdown{
    width: 1%;
}

.upload-container-parsing{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#parsing-load{
    background-color: var(--primary-color-dark);
}

#parsing-text{
    margin-left:20px;
    color: var(--primary-color-dark);
    font-size: 1.4rem;
    font-weight: 700;
}


.upload-container-upload-form{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#upload-container-input-submit{
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
}

#upload-container-input-submit:hover{
    background-color: var(--primary-color-dark);
}

.upload-feedback-container{
    margin-top: 10px;
}

.upload-success{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--green-color);
    font-size: 1.1rem;
    font-weight: 500;
}

.upload-success svg{
    height:1.1rem;
}

.upload-failure{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--red-color);
    font-size: 1.1rem;
    font-weight: 500;
}

.upload-failure svg{
    height:1.1rem;
}



/* For mobile devices in portrait mode (small screens) */
@media (max-width: 480px) {
    /* Styles for small devices like phones in portrait mode */
    .upload-form-container{
        margin:4px;
        padding:12px;
    }

    .upload-container-resume{
        max-width: 100%;
    }
}

@media (max-width: 481px) and (min-width: 768px) {
    /* Styles for small devices like phones in portrait mode */
    .upload-form-container{
        margin:4px;
        padding:12px;
    }

    .upload-container-resume{
        max-width: 80%;
    }
}

@media (max-width:769px) and(min-width: 1024px) {
    /* Styles for small devices like phones in portrait mode */
    .upload-form-container{
        margin:4px;
        padding:12px;
    }

    .upload-container-resume{
        max-width: 70%;
    }
}

@media (max-width:1025px) and (min-width: 1440px) {
    /* Styles for small devices like phones in portrait mode */
    .upload-form-container{
        margin:4px;
        padding:12px;
    }

    .upload-container-resume{
        max-width: 60%;
    }
}

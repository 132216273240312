.jobs-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-diluated-color);
}

.job-search-container{
    width:100%;
    /* border-right:2px solid var(--primary-color); */
    display: flex;
    flex-direction: column;
    max-width:800px;
    
}

.job-feedback-container{
    width:40%;
    display: flex;
    flex-direction: column;
}

.job-actions-container{
    display: flex;
    flex-wrap: wrap;
    width:300px;
}

.job-action{
    max-width: 100px;
    border-radius: 8px;
    background-color: white;
    margin:6px;
    padding:8px;
}

.job-list-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:600px;
    overflow-y: auto;
}

.job-query-container{
    margin:auto;
    width:60%;
    margin-top: 20px;
}

.job-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.job{
    margin:10px;
    padding:12px;
    background-color: white;
    border:2px solid var(--primary-color);
    border-radius: 8px;
    width:60%;
}

.job-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.send-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-radius: 0px 6px 6px 0px;
    padding:6px;
}

@media (max-width: 400px) {
    .jobs-container{
        flex-direction: column;
    }
    .job-search-container{
        width:100%;
    }
    .job-feedback-container{
        width:100%;
    }
    .job-query-container{
        width:100%;
    }
    .job-list{
        width:100%;
    }
    .job{
        width:100%;
    }
}

@media (min-width:401px) and (max-width: 800px) {
    .jobs-container{
        flex-direction: column;
    }
    .job-search-container{
        margin:4px;
        width:98%;
    }
    .job-feedback-container{
        width:100%;
    }
    .job-query-container{
        width:100%;
    }
    .job-list{
        width:100%;
    }
    .job{
        width:100%;
    }
}

@media (min-width: 801px) and (max-width: 1200px) {
    .jobs-container{
        flex-direction: column;
    }
    .job-search-container{
        margin:4px;
        width:70%;
    }
    .job-feedback-container{
        width:100%;
    }
    .job-query-container{
        width:100%;
    }
    .job-list{
        width:100%;
    }
    .job{
        width:100%;
    }
}

@media (min-width: 1201px) {
    .jobs-container{
        flex-direction: row;
    }
    .job-search-container{
        margin:4px;
        width:50%;
    }
    .job-feedback-container{
        width:40%;
    }
    .job-query-container{
        width:100%;
    }
    .job-list{
        width:100%;
    }
    .job{
        width:100%;
    }
}






.creds-container{
    background-color: var(--primary-diluated-color);
}

.creds-loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
}

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:12px 16px;
    background-color: var(--primary-diluated-color);
}

.header-logo{
    width: 60px;
}

.header-logo-container{
    cursor: pointer;
}

.header-btns{
    color: white;
    background-color: var(--primary-color);
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 6px 18px;
    border-radius: 4px;
    cursor: pointer;
    margin-right:40px;
    border:3px solid transparent;


}

.header-dropdown{
    position: relative;
    display: inline-block;
}

.header-dropdown-content{
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    border-radius:6px;
    min-width: 160px;
    padding:4px 0px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    z-index: 1;
}

.header-dropdown-item{
    color: black;
    padding: 4px 10px;
    font-weight: 700;
    text-decoration: none;
    display: block;
}

.header-dropdown-item:hover{
    background-color: var(--primary-color);
    color: white;
}

.show {display:block;}

/* For mobile devices in portrait mode (small screens) */
@media (max-width: 480px) {
    /* Styles for small devices like phones in portrait mode */

    .header-container{
        padding:4px;
    }

    .header-logo{
        width: 30px;
    }

    .header-btns{
        font-size: 0.8rem;
        font-weight: 600;
        padding: 4px 6px;
        margin-right:6px;
    }
}

@media (max-width: 768px) {
    /* Styles for small devices like phones in portrait mode */
    .header-container{
        padding:8px;
    }

    .header-logo{
        width: 50px;
    }

    .header-btns{
        font-size: 0.85rem;
        font-weight: 600;
        padding: 4px 6px;
        margin-right:6px;
    }
}

@media (max-width: 1024px){
    .header-container{
        padding:8px;
    }

    .header-logo{
        width: 50px;
    }

    .header-btns{
        font-size: 0.9rem;
        font-weight: 600;
        padding: 4px 6px;
        margin-right:6px;
    }
}

@media (max-width: 1440px){
    .header-container{
        padding:8px;
    }

    .header-logo{
        width: 50px;
    }

    .header-btns{
        font-size: 1rem;
        font-weight: 600;
        padding: 4px 12px;
        margin-right:12px;
    }
}


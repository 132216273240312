.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: var(--primary-diluated-color);
}

.landing-page-item{
    width:70%;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    background-color: var(--primary-diluated-color);
    text-shadow: 0px 1px 1px var(--icon-color);
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 480px) {
    .landing-page-item{
        width:90%;
        padding: 10px;
        margin: 10px;
    }
    
}
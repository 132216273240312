

.pdf-menu-bar{
    display: flex;
    justify-content:space-between;
    padding: 4px;
    background-color: var(--primary-diluated-color);
}

.pdf-menu-items{
    
}

.pdf-menu-btn{
    margin-left:5px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-weight: 600;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;

}



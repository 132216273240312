.editable-div {
    width: 100%;           /* Full width */
    padding: 10px;         /* Inner padding for better UX */
    height: 95%;          /* Full height */
    border: 1px solid #ced4da; /* Match Bootstrap input style */
    border-radius: 0.75rem; /* Rounded corners */
    background-color: #fff; /* White background */
    overflow-y: auto;      /* Vertical scrolling if content overflows */
    font-size: 1rem;       /* Match Bootstrap input font size */
    outline: none;         /* Remove default focus outline */
    box-sizing: border-box; /* Include padding in width/height */
}
  
.editable-div:focus {
    border-color: var(--primary-color); /* Highlight border on focus */
    box-shadow: 0 0 0 0.7rem rgba(200, 72, 255, 0.05); /* Match Bootstrap input focus */
}
  
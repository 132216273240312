.signup-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-diluated-color);
}

.signup-container-header{
    color:var(--primary-color);
    margin-top: 40px;
    font-weight: bolder;
}

.signup-container-body{
    width: 380px;
    padding: 40px;
}

.signup-warn-text{
    color: var(--primary-color);
    padding:12px;
    font-size: 0.9rem;
    font-weight: 800;
    border:2px solid var(--primary-color);
    margin-bottom: 10px;
    border-radius: 8px;
}

.signup-container-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.btn-custom{
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}


@media (max-width: 400px) {
    .signup-container{
        height: 100%;
    }
    .signup-container-header{
        margin-top: 20px;
    }
    .signup-container-body{
        width: 100%;
        padding: 20px;
    }
    .signup-container-form{
        padding: 0;
    }
    .signup-warn-text{
        font-size: 0.8rem;
    }
}

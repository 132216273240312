.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--primary-diluated-color);
}


.home-container-user-resume{
    display: flex;
    flex-direction: column;
    position:relative;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
    padding: 6px;
    font-weight: 500;
    border:2px solid rgb(164, 164, 164);
    background-color: white;
    border-radius: 8px;
}
.home-container-resume-div1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-container-resume-div1:hover .home-container-set-default-btn{
    display: block;
}

.home-container-set-default-btn{
    cursor: pointer;
    display: none;
    
}
.home-container-item{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-container-item-remove{
    overflow: hidden;

}

.home-container-item-remove:hover .home-container-user-resume{
    border:2px solid red
}

@keyframes slideIn {
    0% {
        border:2px solid transparent;
    }
    20% {
        border:2 solid transparent
    }
    80% {
        border:2px solid red;
    }
    100% {
        border:2px solid red;
    }
}


.home-container-nickname-edit{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-left: 6px;
}

.home-container-view-icon{
    cursor: pointer;
    width:15px;
    height: 15px;
}

.big-container{
    height:400px;
    border-radius: 12px;
    padding:2px;
    background-color: white;
    border:2px solid var(--primary-color);
}

.mini-container{
    height:200px;
    border-radius: 12px;
    background-color: white;
    border: 2px solid var(--primary-color);
}


.home-creds-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-creds-footer{
    position:absolute;
    bottom:10px;
    right:10px;
    display: flex;
    flex-direction: column;
}

.home-create-resume-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-create-resume-footer{
    position:absolute;
    bottom:10px;
    right:10px;
}

.home-resumes-container{
    display: flex;
    flex-direction: column;
}
.home-resumes-body{
    height:350px;
    overflow:auto;
}
.home-resumes-footer{

}

.home-container-btns{
    margin:4px;
}

.home-tailor-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-tailor-footer{
    position:absolute;
    bottom:10px;
    right:10px;
}

.home-q-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-q-footer{
    position:absolute;
    bottom:10px;
    right:10px
}

.home-upload-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-upload-footer{
    position:absolute;
    bottom:10px;
    right:10px
}


.home-stats-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-stats-footer{
    position:absolute;
    bottom:10px;
    right:10px
}

.home-support-container{
    position:relative;
    height: 100%;
    padding:4px;
}

.home-support-footer{
    position:absolute;
    bottom:10px;
    right:10px
}


@media (min-width:769px) and(max-width:1024px){
    .home-container{
        font-size: 0.8rem;
    }

    .home-resumes-footer{
        font-size: 0.8rem;
    }
}







.feedback-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-diluated-color);
}

.feedback-form{
    margin-top: 80px;
    width:30%;
    max-width: 550px;
}

.feedback-container-labels{
    font-weight: 600;
}

@media (max-width: 400px) {
    .feedback-form{
        width: 100%;
    }
}

@media (min-width: 401px) and (max-width: 768px) {
    .feedback-form{
        width: 80%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .feedback-form{
        width: 60%;
    }
}

@media (min-width: 1025px) {
    .feedback-form{
        width: 50%;
    }
}


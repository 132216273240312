.resume-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.resume-input-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.resume-section-title{
    margin-top:20px;
    font-weight: bolder;
}

.resume-section{
    border:2px solid var(--primary-color);
    padding:12px;
    border-radius: 12px;
    position:relative;
    margin-bottom:15px;
}

.resume-sub-section{
    display: flex;
    flex-direction: row;
}

.resume-remove-sub-section{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:5px;
}

.resume-remove-section{
    position:absolute;
    top:-14px;
    right:-14px;
}

@media (max-width: 768px) {
    .resume-container{
        flex-direction: column;
    }
}



.my-custom-badge{
    color: var(--primary-color);
    padding: 0.2rem 0.4rem;
    border-radius: 8px;
    border:2px solid var(--primary-color);
    font-weight: bold;
    font-size: 0.9rem;
    margin: 0.2rem;
    display: inline-block;
    text-align: center;
}

.my-badge-close{
    color: var(--primary-color);
    font-size: 0.8rem;
    margin-left: 0.2rem;
    cursor: pointer;
}

.my-badge-close:hover{
    color: var(--red-color);
}

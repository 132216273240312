.generate-container{
    display: flex;
    flex-direction:column;
    overflow-y: auto;
    height: 100vh;
    overflow: auto;
    background-color: var(--primary-diluated-color);
}

.generate-container-body{
    display: flex;
    flex-direction: row;
    height:100%;
}

.generate-item{
    width:50%;
    padding:4px 20px 16px 20px;
}

.generate-container-btn-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:8px;
}

.generate-container-action-btn{
    padding:3px 6px;
    font-weight: bold;
    margin: 4px;
    font-size: 0.8rem;
    word-wrap: break-word;
    cursor: pointer;
    border-radius: 8px;
}
.generate-container-action{
    border:1.5px solid var(--primary-color);
    color: var(--primary-color);
    background-color: var(--primary-diluated-color);
}

.generate-container-action-selected{
    background-color: var(--primary-color);
    color: white;
    border:1.5px solid var(--primary-color);
}

.generate-container-section-title{
    color:var(--icon-color);
    margin-left: 8px;
    font-size: 1rem;
    font-weight: 700;
}

.generate-container-action-btns{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.generate-container-actions-container{
    display:flex;
    flex-direction: row;
}

#generate-btn{
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right:40px;
    margin-bottom:10px;

    &:hover{
        background-color: var(--primary-color-dark);
    }
}

.generating-loader{
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.resume-download{
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 40px;
    margin-bottom: 10px;
    text-decoration: none;

    &:hover{
        background-color: var(--primary-color-dark);
    }
}


/* For mobile devices in portrait mode (small screens) */
@media (max-width: 780px) {
    /* Styles for small devices like phones in portrait mode */
    .generate-container-body{
        flex-direction: column;
    }
    .generate-item{
        width:100%;
        height: 80%;
        padding:6px;
        margin-top:6px;
    }
    .generate-container-btn-section{
        display: flex;
        flex-direction: column;
    }
}





